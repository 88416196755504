import axios from '@/axios/globalaxios'

// 查询用户管理 信息
export const sysUsers = data =>{
    return axios({
        url: "/tradelab/sysUser/sysUsers",
        method: 'post',
        data:data
    })
}
// 删除 用户信息
export const deleteInfo = data =>{
    return axios({
        url: "/tradelab/sysUser/delete",
        method: 'post',
        data:data
    })
}
// 更新 用户信息
export const saveSysUserRole = data =>{
    return axios({
        url: "/tradelab/sysUser/saveSysUserRole",
        method: 'post',
        data:data
    })
}



